import clsx from "clsx";
import _ from "lodash";
import { useId } from "react";
import Chart from "react-google-charts";
import { useLoaderData } from "react-router";
import { Tooltip } from "react-tooltip";
import Alert from "~/components/alert";
import { GOOGLE_CHART_COLORS } from "~/components/chart";
import { IconHelp } from "~/components/icons";
import Panel from "~/components/panel";
import type { DynamicMetricsQuery } from "~/types/api";
import { formatDate } from "~/utils/dates";
import { formatMoney, formatNumber } from "~/utils/formatting";

type Props = {
  height?: string;
  barLabel: string;
  chartLabel: string;
  goalField: string;
  actualField: string;
  targetField?: string;
  format?: "Accounting" | "Number" | "Percentage" | "Count";
  className?: string;
  footer?: React.ReactNode;
  underIsGood?: boolean;
  tooltip?: React.ReactNode;
  useCount?: boolean;
};

export default function GenericBarChart({
  height = "251px",
  barLabel,
  chartLabel,
  goalField,
  actualField,
  targetField,
  format,
  className,
  footer,
  underIsGood,
  tooltip,
  useCount
}: Props) {
  const { dynamicMetrics } = useLoaderData<{
    dynamicMetrics: DynamicMetricsQuery["dynamicMetrics"];
  }>();

  const id = useId();

  const items: (string | number | { role: string })[][] = [
    [
      "Label",
      barLabel,
      { role: "annotation" },
      { role: "style" },
      ...(targetField
        ? ["Target", { role: "annotation" }, { role: "style" }]
        : []),
      "Goal",
      { role: "annotation" },
      { role: "style" }
    ]
  ];

  const dates = _.uniq(
    dynamicMetrics
      .filter(
        (m) => m.name && [actualField, goalField, targetField].includes(m.name)
      )
      .map((m) => m.date)
      .filter(Boolean)
  ).sort();

  for (const date of dates) {
    const goal = dynamicMetrics.find(
      (g) => g.date === date && g.name === goalField
    );

    const actual = dynamicMetrics.find(
      (g) => g.date === date && g.name === actualField
    );
    const a = useCount
      ? actual?.count || 0
      : Number.parseFloat(actual?.sum || "0");
    const target = dynamicMetrics.find(
      (g) => g.date === date && g.name === targetField
    );
    const b = useCount
      ? target?.count || 0
      : Number.parseFloat(target?.sum || "0");

    const c = Number.parseFloat(goal?.sum || "0");
    const formatter =
      format === "Accounting"
        ? (value: number) => formatMoney(value, { decorator: true })
        : format === "Percentage"
          ? (value: number) =>
              formatNumber(value, { format: "0.0%", zeroes: true })
          : format === "Count"
            ? (value: number) =>
                formatNumber(value, { format: "0,0", zeroes: true })
            : (value: number) => formatNumber(value, { zeroes: true });

    const comparisonNum = targetField ? b * 0.9 : c;
    const missed = underIsGood ? a > comparisonNum : a < comparisonNum;
    items.push([
      formatDate(date, { format: "MMM YYYY" }),
      a,
      `Actual: ${formatter(a)}`,
      missed ? GOOGLE_CHART_COLORS.missed : GOOGLE_CHART_COLORS.actual,
      ...(targetField
        ? [b, `Target: ${formatter(b)}`, GOOGLE_CHART_COLORS.target]
        : []),
      c,
      `Goal: ${formatter(c)}`,
      GOOGLE_CHART_COLORS.goal
    ]);
  }

  const maxValue =
    _.sortBy(
      items
        .slice(1)
        .flatMap((i) => (targetField ? [i[1], i[4], i[7]] : [i[1], i[4]]))
    ).at(-1) || 0;

  return (
    <Panel className={clsx("mb-0", className)}>
      <Panel.Header
        title={chartLabel}
        titleClassName="text-center"
        floatButton
        button={
          tooltip && (
            <div className="mt-1.5">
              <IconHelp data-tooltip-id={id} />
              <Tooltip
                content={_.isString(tooltip) ? tooltip : undefined}
                render={_.isString(tooltip) ? undefined : () => tooltip}
                id={id}
                className="z-200 max-w-md"
                place="bottom"
              />
            </div>
          )
        }
      />
      {items.length === 1 ? (
        <Alert mode="warning">No data</Alert>
      ) : (
        <Chart
          height={height}
          width="auto"
          chartType="BarChart"
          data={items}
          options={{
            chartArea: {
              top: 15,
              right: 15,
              bottom: 15,
              left: 70,
              height: "100%",
              width: "100%"
            },
            bar: {
              groupWidth: "90%"
            },
            hAxis: {
              minValue: 0,
              viewWindow: {
                min: 0,
                max: maxValue
              },
              ticks: [],
              textPosition: "none"
            },
            legend: "none"
          }}
        />
      )}
      {footer}
    </Panel>
  );
}
